export const environment = {
    production: true,
    baseUrl: 'https://api-qat.crm.stmatech.com/v1',
    clientId: '41939584-1e28-476a-abaa-ff90fa06caae',
    authority:
      'https://login.microsoftonline.com/69338af3-24c9-471e-ad0c-fc4eea039570',
    redirectUri: 'https://crm-qat.stmatech.com',
    readAllScope: 'https://api-qat.crm.stmatech.com/Members.Read.All',
    readWriteScope: 'https://api-qat.crm.stmatech.com/SupportTickets.ReadWrite.All'
  };
  
